<template>
    <div class="container">
        <div style="background-color:#eee9ed; font-size: 20px; text-align: center; margin-bottom: 5px;">
            <h1><strong style="color:black;">Página de Entradas e Saídas</strong></h1>
        </div>

        <div class="container-box">
            <div class="field">
                <label class="label">Data:</label>
                <input style="width:200px;" type="date" v-model="dt" class="input" placeholder="Digite o Valor" />
            </div>

            <div class="field">
                <label class="label">Tipo Historico:</label>
                <div class="select is-link">
                    <select v-model="tipoHistorico" style="width:200px;">
                        <option v-for="opHistorico in opHistorico" v-bind:value="opHistorico.value">
                            {{ opHistorico.text }}
                        </option>
                    </select>
                </div>
            </div>

        </div>

        <div class="field">
            <label class="label">Historico:</label>
            <input style="width:420px;" type="text" v-model="historico" class="input"
                placeholder="Digite o Histórico" />
        </div>

        <div class="container-box">
            <div class="field">
                <label class="label">Tipo Lançamento:</label>
                <div class="select is-link">
                    <select v-model="tipoLanc" style="width:200px;">
                        <option v-for="opTipoLanc in opTipoLanc" v-bind:value="opTipoLanc.value">
                            {{ opTipoLanc.text }}
                        </option>
                    </select>
                </div>
            </div>

            <div class="field">
                <label class="label">Valor:</label>
                <input style="width:200px;" type="number" step="0.010" v-model="valor" class="input"
                    placeholder="Digite o Valor" />
            </div>
        </div>
        <hr>
        <div class="container-box">
            <div style="text-align: end;">
                <button class="button is-info is-outlined" @click="gravar()">Gerar Lançamento</button>
            </div>
        </div>


    </div>

</template>

<script>
import { getUserInfo } from "@/lib/tools";
import axios from "axios";
export default {
    data() {
        return {
            congregacaoId: "",
            dt: '',
            valor: "",
            dados: [], // Inicializado como array vazio
            dadosCaixa: [],
            showModal: false,
            nome: "",
            url: process.env.VUE_APP_API_URL,
            port: process.env.VUE_APP_PORT,

            tipoHistorico: '',
            opHistorico: [
                { text: 'Ofertas', value: '1' },
                { text: 'Dizimos', value: '2' },
                { text: 'Financeiro', value: '3' },

            ],
            tipoLanc: '',
            opTipoLanc: [
                { text: 'Crédito', value: '1' },
                { text: 'Débito', value: '2' }

            ],
            historico: '',
        };
    },
    mounted() {
        const userInfo = getUserInfo();
        this.idCongregacao = userInfo.congregacaoId;
        //this.getMembros(userInfo);
        console.log(userInfo);
        this.congregacaoId = userInfo.congregacaoId;
        console.log('<<<<<<' + this.congregacaoId + '>>>>>>>>>>');

    },
    methods: {

        gravar(){
            console.log('Entrei no gravar.......')
            axios.post(this.url + ":" + this.port + "/financeiro", {
                data: this.dt,
                congregacaoId: this.congregacaoId,
                tipoHistorico: this.tipoHistorico,
                historico: this.historico,
                tipoLanc: this.tipoLanc,
                valor: this.valor
            }).then((res) => {
                console.log(res);
                this.$router.push('/lancamentos');
            }).catch((err) => {
                console.log(err);
            });
            this.$router.push('/lancamentos');
        }

    }
}
</script>

<style scoped>
.container {
    display: block;
    gap: 80px;
}

.container-box {
    display: flex;
    width: 50vw;
    /*height: 60vh;
    border: 2px solid #ccc;
    background-color: #fff;*/
    overflow-y: auto;
    /* Faz o overflow da lista */
    text-align: center;
    gap: 20px;

}

.container-box2 {}
</style>