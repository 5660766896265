<template>
    <!-- //#869e85e9; -->
    <div class="table-container">
        <div style="background-color:#eee9ed; font-size: 20px; text-align: center; margin-bottom: 5px;">
            <h1> <strong style="color:black; ">Página de Membros</strong></h1>
        </div>
        <div style="display: flex;">
            <input type="search" v-model="nome" class="input" placeholder="Digite a Nome">
            <button style="margin-left: 5px !important;" class="button is-info is-outlined"  @click="buscaNome()">
                <img src="../assets/lupa.jpg" width="30" height="auto">
            </button>
        </div>

        <table class="table">
            <thead>
                <tr>
                    <th>#</th>
                    <th>Nome</th>
                    <th>Endereço</th>
                    <th>Bairro</th>
                    <th>UF</th>
                    <th>CEP</th>
                    <th>Celular</th>
                    <th>Opões</th>


                    <th>
                        <button class="button is-info is-outlined" @click="$router.push('/membro/novo')">
                            <img src="../assets/icons8-add-properties-64.png" width="30" height="auto">
                            <!-- Delete -->
                        </button>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="dados in dados" v-bind:key="dados.id">
                    <td>{{ dados.id }}</td>
                    <td>{{ dados.nome }}</td>
                    <td>{{ dados.endereco }}</td>
                    <td>{{ dados.bairro }}</td>
                    <td>{{ dados.estado }}</td>
                    <td>{{ dados.cep }}</td>
                    <td>{{ dados.celular }}</td>
                    <td>
                        <button class="button is-danger is-outlined" @click="mostrar(dados.id,dados.nome)">
                            <img src="../assets/botao-de-deletar.png" width="30" height="auto">

                        </button>
                    </td>
                    <td>
                        <button class="button is-success is-outlined"
                            @click="$router.push('/membro/edit/' + dados.id)">
                            <img src="../assets/icons8-edit-account-64 (1).png" width="30" height="auto">
                        </button>
                    </td>
                </tr>
            </tbody>
        </table>
        <div :class="{ modal: true, 'is-active': showModal }">
            <div class="modal-content">
                <div class="msgModal">
                    <p><strong>Deseja deletar esse Registro!</strong></p>
                    <p>{{ this.nome }}</p>
                    <div class="munuBtn">
                        <button name="btnModal" id="btnModal" class="btn-novo" @click="fecharModal()">
                            Cancelar
                        </button>ㅤㅤ
                        <button name="btnModal" id="btnModal" class="btn-excluir" @click="excluir()">
                            Excluir
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { getUserInfo } from '@/lib/tools';
import axios from 'axios';
export default {
    data() {
        return {
            msg: null,
            id: "",
            showModal: false,
            nome: "",
            endereco: "",
            email: "",
            cep: "",
            bairro: "",
            celular: "",
            dados: null,
            idCongregacao: null,
            url: process.env.VUE_APP_API_URL,
            port: process.env.VUE_APP_PORT,
        }
    },
    methods: {
        async getMembros() {
            var userInfo = getUserInfo()
            console.log(userInfo);
            this.congregacaoId = userInfo.congregacaoId;
            console.log('congregação ID .........:'+userInfo.congregacaoId);

            console.log('<<<<<<<<<<< userInfo   >>>>>>>>>>');
            console.log(userInfo);
            console.log(userInfo.nivel);
            if((userInfo.nivel == 1) || (userInfo.nivel == 3 )){
                var req = {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("tokenIgreja"),
                    },
                 }; 

                //idCongregacao
                //axios.get(this.url + ":" + this.port + "/membros",  )   //req

                console.log(this.congregacaoId);
                await axios.get(this.url + ":" + this.port + "/membros", {
                params: {
                    congregacaoId: this.congregacaoId
                    
                    },
                    ...req
                }).then((res) => {
                        this.dados = res.data;
                        console.log(res);
                    }).catch((error) => {
                        console.log(error);
                    });
            }
            
        },
        buscaNome(nome){
            var nome = this.nome; 
            console.log("nome================>"+nome);
            console.log("idCongregação================>"+this.congregacaoId);
            //axios.get(this.url + ":" + this.port + "/membro/"+nome, {})   //req
            axios.get(this.url + ":" + this.port + "/membro", {
                params: {
                    nome: this.nome,   
                    congregacaoId: this.congregacaoId
                }  
            }) 
                .then((res) => {
                    this.dados = res.data;
                    console.log(res);
                }).catch((error) => {
                    console.log(error);
                });
        },
        mostrar(id, nome) {
            this.id = id;
            this.nome = nome;
            this.showModal = true;
            console.log("<<<<<<<    Id ------------>>>>>  " + this.id);
        },
        excluir() {
            this.showModal = true;
            this.deleteMembro(this.id);
        },
        fecharModal() {
            this.showModal = false;
        },
        async deleteMembro(id) {
            //var r = confirm("Deseja excluir esse Registro!");

            this.showModal = false;

            /* var req = {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            }; */

            axios
                .delete(this.url + ":" + this.port + "/membro/deletar/" + id) //, req
                .then((res) => {
                    console.log(res);
                    //this.$router.push('/congregacao');
                    this.getMembros();
                })
                .catch((err) => {
                    this.msg = err.response.data.error;
                    console.log(this.msg);
                    setTimeout(() => (this.msg = ""), 3000);
                });
            //}
        }
    },
    mounted() {

        this.getMembros();
    }

}
</script>

<style scoped>
.munuBtn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.msgModal {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    border: solid 2px;
    background-color: darkgray;

    height: 140px;
    width: 380px;
    border-radius: 20px;
    font-size: large;
    /*   align-content: center;
    align-items: center; */
}

#btnModal {
    width: 140px;
}

.table {
    width: 100%;
}

.table tr th {
    padding: 8px;
}

.btn-novo {
    color: black;
    border: solid 1px #2196f3;
    padding: 10px;
    border-radius: 4px;
}

.btn-excluir {
    color: #2196f3;
    border: solid 1px #2196f3;
    padding: 10px;
    border-radius: 4px;
}

.btn-novo:hover {
    background-color: #2196f3;
    color: #fff;
}

.btn-excluir:hover {
    background-color: #ca2b25;
    color: #fff;
}
</style>