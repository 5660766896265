import axios from 'axios';

function AdminAuth(to, from, next) {
  var url = process.env.VUE_APP_API_URL
  var port = process.env.VUE_APP_PORT

  console.log(localStorage.getItem('tokenIgreja'));

  if (localStorage.getItem('tokenIgreja') != undefined) {
    console.log('Entrei ***************************');
    var req = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem('tokenIgreja')
      }

    }
    console.log('index.js------>   ' + req.Authorization);
    console.log(url + ":" + port + "/validate");
    axios.post(url + ":" + port + "/validate", {}, req).then(res => {  //menu: 'Secretaria'
      console.log(res);
      next();
    }).catch(err => {
      console.log(err.response);
      next("/login");
    })
    next();
  } else {
    next("/login");
  }
}


import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import Register from '../views/Register.vue'

import CongPage from '../views/Congregacao/CongregacaoView.vue'
import IncluirCongregacao from '../views/Congregacao/pageIncluirCongregacao.vue'
import EditCongregacao from '../views/Congregacao/pageEditCongregacao.vue'


import MembroPage from '../views/Membros/membroView.vue'
import MembroNovo from '../views/Membros/membroNovo.vue'
import MembroEdit from '../views/Membros/membroEdit.vue'
import relAniversariantes from '../views/Membros/relAniversariantes.vue'
import relMembros from '../views/Membros/relMembros.vue'
//import fichaCadastral from '../components/RelFichasMembros.vue'

import PageUsuario from '../views/Usuarios/pageUsuarios.vue'
import UsuarioPage from '../views/Usuarios/usuarioPage.vue'
import UsuarioEditPage from '../views/Usuarios/EditUsuario.vue'

import Login from '../views/LoginView.vue'

import PageDizimo from '../views/Financeiro/PageDizimo.vue'
import PageLancamentos from '../views/Financeiro/PageLancamentos.vue'
import PageLancamentoNovo from '../views/Financeiro/PageLancamentoNovo.vue'
import PageLancamentoEdit from '../views/Financeiro/PageLancamentoEdit.vue'
import PageTranfDizimo from '../views/Financeiro/PageTransfDizimo.vue'

//var nivel = 0;
const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
  },
  {
    path: '/register',
    nome: 'Register',
    component: Register,

  },
  {
    path: '/congregacao',
    name: 'congPage',
    component: CongPage,
    //meta: {menu: 'Secretaria'},
    //query : { menu: 'Secretaria'},
    //params: { menu: 'Secretaria' },
    beforeEnter: AdminAuth,
    props: { menu: 1 }

  },
  {
    path: '/congregacao/nova',
    nome: 'pageIncluirCongregacao',
    component: IncluirCongregacao
    , beforeEnter: AdminAuth
  },
  {
    path: '/congregacao/edit/:id',
    nome: 'pageEditCongregacao',
    component: EditCongregacao
    , beforeEnter: AdminAuth
    //,props: { Nivel: '2' }
  },
  {
    path: '/membro',
    nome: 'MembroPage',
    component: MembroPage,

    beforeEnter: AdminAuth
  },
  {
    path: '/membro/novo',
    nome: 'MembroNovo',
    component: MembroNovo,
    beforeEnter: AdminAuth
  },
  {
    path: '/membro/edit/:id',
    nome: 'membroEdit',
    component: MembroEdit,
    beforeEnter: AdminAuth
  },
  {
    path: '/membro/relAniversariantes',
    nome: 'relAniversariantes',
    component: relAniversariantes,
    beforeEnter: AdminAuth
  },
  {
    path: '/membro/fichaMembros',
    nome: 'relMembros',
    component: relMembros,
    beforeEnter: AdminAuth
  },
  /*   {
      path: '/membro/fixascadastral',
      name: 'fixascadastral',
      component: fichaCadastral,
      beforeEnter: AdminAuth
    }, */
  {
    path: '/usuario',
    nome: 'pageUsuarios',
    component: PageUsuario,
    beforeEnter: AdminAuth
  },
  {
    path: '/usuario/novo',
    nome: 'usuarioPage',
    component: UsuarioPage,
    beforeEnter: AdminAuth
  },
  {
    path: '/usuario/edit/:id',
    nome: 'UsuarioEditPage',
    component: UsuarioEditPage,
    beforeEnter: AdminAuth
  },
  {
    path: '/dizimos',
    nome: 'PageDizimo',
    component: PageDizimo,
    beforeEnter: AdminAuth
  },
  {
    path: '/lancamentos',
    nome: 'PageLancamentos',
    component: PageLancamentos,
    beforeEnter: AdminAuth
  },
  {
    path: '/financeiro/novo',
    nome: 'PageLancamentoNovo',
    component: PageLancamentoNovo,
    beforeEnter: AdminAuth
  },
  {
    path: '/financeiro/edit/:id',
    nome: 'PageLancamentoEdit',
    component: PageLancamentoEdit,
    beforeEnter: AdminAuth
  },
  {
    path: '/financeiro/transferenciaDizimo',
    nome: 'PageTranfDizimo',
    component: PageTranfDizimo,
    beforeEnter: AdminAuth
  },
  {
    path: '/login',
    nome: '/LoginView',
    component: Login
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
