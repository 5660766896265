<template>
    <div style="text-align: center;">
        <div style="background-color:#eee9ed; font-size: 20px; text-align: center; margin-bottom: 5px;">
            <h1><strong style="color:black;">Página de Dizímos</strong></h1>
        </div>

        <div class="container">
            
            <div class="container-box">
                <div style="background-color:#eee9ed; font-size: 20px; text-align: center; margin-bottom: 5px;">
                    <h1><strong style="color:black;">Inclusão dos Dizímos</strong></h1>
                </div>
                <div>
                    <div class="field">
                        <div class="select is-link">
                            <label class="label">Dizimista:</label>
                            <select v-model="idMembro" class="select" style="width:400px;" @change="handleChange">
                                <option v-for="item in dados" :value="item.id">{{ item.nome }}</option>
                            </select>
                        </div>&nbsp;&nbsp;
                    </div>

                    <div class="field">
                        <label class="label">Data:</label>
                        <input style="width:200px;" type="date" v-model="dt" class="input"
                            placeholder="Digite o Valor" />
                    </div>
                    <div class="field">
                        <label class="label">Valor:</label>
                        <input style="width:200px;" type="number" step="0.010" v-model="valor" class="input"
                            placeholder="Digite o Valor" />
                    </div>
                    <div style="text-align: center;">
                        <button class="button is-info is-outlined" @click="gravar()">Gerar Dizímo</button>
                    </div>
                </div>

            </div>

            <div class="container-box">
                <div style="background-color:#eee9ed; font-size: 20px; text-align: center; margin-bottom: 5px;">
                    <h1><strong style="color:black;">Consulta dos Dizímos</strong></h1>
                </div>

                <table class="table" style="min-width:50%; max-height:5vh !important; overflow:visible !important">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Data</th>
                            <th>Valor</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="item in dadosDizimista" :key="item.id">
                            <td>{{ item.id }}</td>
                            <td>{{ item.data }}</td>
                            <td>{{ item.valor }}</td>
                            <td>
                                <button class="button is-danger is-outlined" @click="mostrar(item.id, item.nome)">
                                    <img src="../../assets/botao-de-deletar.png" width="30" height="auto" />
                                </button>
                            </td>
                            <div :class="{ modal: true, 'is-active': showModal }">
                                <div class="modal-content">
                                    <div class="msgModal">
                                        <p><strong>Deseja deletar esse Registro!</strong></p>
                                        <p>{{ nome }}</p>
                                        <div class="munuBtn">
                                            <button name="btnModal" class="btn-novo"
                                                @click="fecharModal()">Cancelar</button>
                                            <button name="btnModal" class="btn-excluir"
                                                @click="excluir(item.id)">Excluir</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </tr>
                    </tbody>
                </table>
            </div>

        </div>
    </div>
  
</template>

<script>
import { getUserInfo } from "@/lib/tools";
import axios from "axios";

export default {
    data() {
        return {
            congregacaoId: "",
            idMembro: "",
            dt: '',
            valor: "",
            dados: [], // Inicializado como array vazio
            dadosDizimista: [],
            showModal: false,
            nome: "",
            url: process.env.VUE_APP_API_URL,
            port: process.env.VUE_APP_PORT,
        };
    },
    mounted() {
        const userInfo = getUserInfo();
        this.idCongregacao = userInfo.congregacaoId;
        this.getMembros(userInfo);
        console.log(userInfo);
        this.congregacaoId = userInfo.congregacaoId;
        console.log('<<<<<<' + this.congregacaoId + '>>>>>>>>>>');
    },
    methods: {
        async getMembros(userInfo) {
            if (userInfo.nivel === 2 || userInfo.nivel === 3) {
                const url = `${this.url}:${this.port}/membros`;
                try {
                    const response = await axios.get(url, {
                        params: { congregacaoId: userInfo.congregacaoId },
                    });
                    this.dados = response.data;
                } catch (err) {
                    console.error("Erro ao buscar membros:", err.response?.data?.error || err.message);
                }
            }
        },
        handleChange(event) {
            console.log('Campo alterado:', event.target.value);
            this.getDizimista(this.idMembro)
            
        },
        async getDizimista(id) {
            const userInfo = getUserInfo();
            if (userInfo.nivel === 2 || userInfo.nivel === 3) {
                const url = `${this.url}:${this.port}/dizimista/${this.idMembro}`;
                console.log(url);
                try {
                    const response = await axios.get(url, {
                        params: { id: id },
                    });
                    console.log(response.data)
                    this.dadosDizimista = response.data;
                } catch (err) {
                    console.error("Erro ao buscar Dizimista:");
                }
            }
        },
        mostrar(id, nome) {
            this.showModal = true;
            this.nome = nome;
            this.idMembro = id;
        },
        fecharModal() {
            this.showModal = false;
            this.nome = "";
        },
        excluir(id) {
            console.log(`Excluindo registro com ID ${id}`);
            this.fecharModal();

            const userInfo = getUserInfo();
            if (userInfo.nivel === 2 || userInfo.nivel === 3) {
                const url = `${this.url}:${this.port}/dizimo/${id}`;
                try {
                    const response = axios.delete(url, {
                        params: { id: id },
                    });

                } catch (err) {
                    console.error("Erro ao deletar Dizimista:");
                }
            }
            this.getDizimista(this.idMembro);
        },
        gravar() {

            axios.post(this.url + ":" + this.port + "/dizimo", {
                data: this.dt,
                membroId: this.idMembro,
                congregacaoId: this.congregacaoId,
                valor: this.valor
            }).then((res) => {

                console.log(res);
            }).catch((err) => {
                console.log(err);
            });
            console.log('Codigo Dizimista: '+this.idMembro)
            this.getDizimista(this.idMembro)
            this.limpaCampos();
            console.log(`Dizímo gerado com sucesso!`);
            //Atualizar tabela

            this.dados.find[this.idMembro];

        },
        limpaCampos() {
            this.dt = '';
            this.nome = '';
            this.idMembro = '';
            this.valor = '';
        },
    },
};
</script>

<style scoped>
.container {
    display: flex;
    gap: 80px;
}

.container-box {

    width: 50vw;
    height: 60vh;
    border: 2px solid #ccc;
    background-color: #fff;
    overflow-y: auto; /* Faz o overflow da lista */
    text-align: center;

}


.munuBtn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.msgModal {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    border: solid 2px;
    background-color: darkgray;

    height: 140px;
    width: 380px;
    border-radius: 20px;
    font-size: large;
    /*   align-content: center;
    align-items: center; */
}

#btnModal {
    width: 140px;
}
.btn-novo {
    color: #2196f3;
    border: solid 1px #2196f3;
    padding: 10px;
    border-radius: 4px;
    width: 100px;
    height: 40px;
    padding: 10px;

}

.btn-excluir {
    color: #2196f3;
    border: solid 1px #2196f3;
    padding: 10px;
    border-radius: 4px;
    width: 100px;
    height: 40px;
    padding: 10px;
}

.btn-novo:hover {
    background-color: #2196f3;
    color: #fff;
    font-weight: bold;
}

.btn-excluir:hover {
    background-color: #ca2b25;
    color: #fff;
    font-weight: bold;
}

</style>


<!--                    
                    <select v-model="idMembro" style="width:500px;">
                        <option v-for="listaMembros in listaMembros" v-bind:value="listaMembros.value">
                            {{ listaMembros.text }}
                        </option>
                    </select> -->
