<template>
  <div>
    <article class="panel is-outlined">

      <p class="panel-heading">
        Cadastro de Usuários - Modulo: {{ formType }}
      </p>
      <span style="position:absolute; top: 40%; left: 35%;
        z-index:1000; background-color:#333; color:#fff; width: 20%;
        height:auto;text-align:center; border-radius:10px; "> {{ msg }} </span>
      <div class="principal">

        <div>
          <label>Nome:</label><br>
          <input v-model="nome" class="input" type="text" id="nome" style="width: 400px;" />&nbsp &nbsp
        </div>

        <div>
          <label>Nível:</label><br>
          <select v-model="nivel" class="select">
            <option v-for="option in opNivel" v-bind:value="option.value">
              {{ option.text }}
            </option>
          </select>&nbsp &nbsp
        </div>

      </div>

      <div class="principal">
        <div v-if="props.formType == 'create'">
          <label>Senha:</label><br>
          <input v-model="senha" class="input" type="password" id="senha" style="width: 400px;" />&nbsp &nbsp
        </div>
        <div class="field">
          <label>Congregação:</label><br>
          <select v-model="igrejaId" class="select">
            <option v-for="_igreja in dadosIgreja"  :value="_igreja.id">{{ _igreja.razaoSocial }}</option>
         </select>   
        </div>
      </div>

      <div class="btn">
        <br>
        <button class="button is-info is-outlined" @click="submit()">{{ formType == 'create' ? 'C a d a s t r a r' : 'A l t e r a r'}}</button><br><br>
      </div>

    </article>

  </div>
</template>
  
<script setup>

import { ref, defineProps, onBeforeMount, onMounted } from 'vue';
import router from '../router';
import { fetcher } from '../lib/fetcher'


const props = defineProps({
  formType: String,
  usuario: Object
});

const nome = ref(props.usuario ? props.usuario.nome : '');
const nivel = ref(props.usuario ? props.usuario.nivel.toString() : '');
const senha = ref('');
const igrejaId = ref(props.usuario ? props.usuario.congregacaoId.toString() : '');
const msg = ref(null)

const opNivel = ref([
  { text: 'Secretário', value: '1' },
  { text: 'Tesoureiro', value: '2' },
  { text: 'Pastor', value: '3' }
])

const dadosIgreja = ref([]);

onMounted(() => {

  console.log('props ', props.usuario);


  fetcher.get("/igrejas", {
  })
    .then((res) => {
      console.log(res.data);
      dadosIgreja.value = res.data;
      igrejaId.value = props.usuario.congregacaoId.toString()
    })
    .catch((err) => {
      // this.msg = err.response.data.error;
      console.log('erro', err);
      // setTimeout(() => (this.msg = ""), 3000);
    });
})

function submit() {
  console.log(props)
  if(props.formType == 'create') 
    createUser();
  else 
    updateUser();
}

function createUser() {
  console.log('Criando usuario')
  fetcher.post("/usuario/new",{
        nome: nome.value,
        nivel: nivel.value,
        senha: senha.value,
        congregacaoId: igrejaId.value
        })
        .then((res) => {
          msg.value = "Usuário cadastrado com sucesso";
          setTimeout(() => (msg.value = ""), 3000);
          router.push('/usuario');
          console.log(res);
        })
        .catch((err) => {
          console.log(err)
          msg.value = err.response.data.error;
          console.log(msg.value);
          setTimeout(() => (msg.value = ""), 3000);
        });
     
}

function updateUser() {
  fetcher.put(`/usuario/update`,{
        id: props.usuario.id,
        nome: nome.value,
        nivel: nivel.value,
        congregacaoId: igrejaId.value
        }).then((res) => {
          router.push('/usuario');
        }).catch((err) => {
          console.log(err)
        })
}

</script>
  
<style scoped>
.lbl {
  font-size: 10px;
  font-weight: bold;
  margin-left: 2%;
}

.principal {
  display: flex;
  flex-direction: row;
  margin-left: 2%;
}

.principal label {
  font-size: 12px;
  font-weight: bold;
  margin-left: 2%;
}

.btn {
  margin-left: 2%;
}
</style>