<template>

    <div class="table-container">
        <div style="background-color:#eee9ed; font-size: 20px; text-align: center; margin-bottom: 5px;">
            <h1> <strong style="color:black; ">Página de Tranferência de Dizimos p/Financeiro</strong></h1>
        </div>
        <div style="display: flex; border: 0px, 1px solid; gap: 20px; align-content: center;">
            
            <div class="field">
                <label class="label">Escolha o Mês:</label>
                <div class="select is-link">
                    <select v-model="mes" style="width:200px;">
                        <option v-for="opmeses in opmeses" v-bind:value="opmeses.value">
                            {{ opmeses.text }}
                        </option>
                    </select>
                </div>
            </div>

            <div class="field">
                <label class="label">A N O:</label>
                <input style="width:80px;" type="number" v-model="ano" class="input" placeholder="Digite o Valor" />
            </div>

            <div class="field">
                <label class="label">T O T A L:</label>
                <label class="label" style="width:180px;" >{{ total }}</label>
                
            </div>

            <div class="field">
                <label class="label">Visualizar:</label>
                 <button style="margin-left: 5px !important;" class="button is-info is-outlined"  
                    @click="VisualizaDizimos(mes)">Ok
                 </button>
              </div>
              <div class="field">
                <label class="label">Transferir:</label>
                 <button style="margin-left: 5px !important;" class="button is-info is-outlined"  
                    @click="TransfereDizimos(mes)">Confirma
                 </button>
              </div>
              <div class="field">
                <label class="label">Imprimir:</label>
                 <button style="margin-left: 5px !important;" class="button is-info is-outlined"  
                    @click="generatePDF()">Imprimir
                 </button>
              </div>
        </div>

        <div style="height: 380px; overflow-y: auto;">

            <table class="table" >
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Data</th>
                        <th>Nome</th>
                        <th>Valor</th>

                    </tr>
                </thead>
                <tbody>
                    <tr v-for="dados in dados" v-bind:key="dados.id">
                        <td>{{ dados.id }}</td>
                        <td>{{ dados.data }}</td>
                        <td>{{ dados.nome }}</td>
                        <td>{{ dados.valor }}</td>

                    </tr>
                </tbody>
            </table>
        </div>


    </div>
</template>
<script>
import { getUserInfo } from '@/lib/tools';
import axios from 'axios';
import * as moment from 'moment'
import jsPDF from 'jspdf';
import 'jspdf-autotable';
export default {
    data() {
        return {
            congregacaoId: "",
            mesAno: '',
            mes: 1,
            ano: 2025,
            total: 0,
            data: Date,
            dados: [], 
            url: process.env.VUE_APP_API_URL,
            port: process.env.VUE_APP_PORT,
            opmeses: [
                { text: 'Janeiro', value: '1' },
                { text: 'Fevereiro', value: '2' },
                { text: 'Março', value: '3' },
                { text: 'Abril', value: '4' },
                { text: 'Maio', value: '5' },
                { text: 'Junho', value: '6' },
                { text: 'Julho', value: '7' },
                { text: 'Agosto', value: '8' },
                { text: 'Setembro', value: '9' },
                { text: 'Outubro', value: '10' },
                { text: 'Novembro', value: '11' },
                { text: 'Dezembro', value: '12' }

            ]
        }
    },
    methods: {

        async VisualizaDizimos(mes){
            this.mes = mes;
            console.log('Ano..: '+this.ano);
            console.log('Mês..: '+this.mes);

            this.total = 0;
            var userInfo = getUserInfo()
            
            this.congregacaoId = userInfo.congregacaoId;

            if (userInfo.nivel === 2 || userInfo.nivel === 3) {
                const url = `${this.url}:${this.port}/financeiro/visualizadizimos`;
                console.log(url);
                try {
                    const response = await axios.get(url, {
                        params: { 
                            congregacaoId: this.congregacaoId,
                            mes: this.mes,
                            ano: this.ano

                         },
                    }).then((res) => {
                        console.log(res)
                        this.dados = res.data[0];

                        if(this.dados.length === 0){
                             alert('Não encontramos dizimos neste periodo!');
                        }
                                              

                    }).catch((err) => {
                          console.log(err);
                    });
            
                } catch (err) {
                    console.error("Erro ao buscar Dizimista:");
                }

                
                for (var i = 0, row; row = this.dados[i]; i++) {
                            this.total += parseFloat(row.valor);
                }

                this.total = this.total.toFixed(2); 
                console.log(this.total);
                    
            }


        },

        async TransfereDizimos(mes){
            this.mes = mes;
            console.log('Ano..: '+this.ano);
            console.log('Mês..: '+this.mes);

            this.total = 0;
            var userInfo = getUserInfo()
            
            this.congregacaoId = userInfo.congregacaoId;

            if (userInfo.nivel === 2 || userInfo.nivel === 3) {
                const url = `${this.url}:${this.port}/financeiro/transfdizimos`;
                console.log(url);
                try {
                    const response = await axios.get(url, {
                        params: { 
                            congregacaoId: this.congregacaoId,
                            mes: this.mes,
                            ano: this.ano

                         },
                    }).then((res) => {
                        console.log(res)
                        if(this.dados.length != 0){
                            this.$router.push('/lancamentos');
                        }

                    }).catch((err) => {
                          console.log(err);
                    });
            
                } catch (err) {
                    console.error("Erro ao buscar Dizimista:");
                }

                
                for (var i = 0, row; row = this.dados[i]; i++) {
                            this.total += parseFloat(row.valor);
                }

                this.total = this.total.toFixed(2); 
                console.log(this.total);
                    
            }
        },
        mostrar(id, historico) {
            this.id = id;
            this.nome = nome;
            this.showModal = true;
            console.log("<<<<<<<    Id ------------>>>>>  " + this.id);
        },
        generatePDF() {
            const doc = new jsPDF();

            // Cabeçalho
            const title = "Assembleia de Deus - Central Rigoleto";
            const title2 = "Relatório Dizimista - "+this.mes+'/'+this.ano;
            const headers = [['Id', 'Data', 'Nome', 'Valor']];
            const footer = 'EMDL Soluções em Informática';

            // Adicionando o título
            doc.setFontSize(18);
            doc.text(title, 45, 20);
            doc.setFontSize(14);
            doc.text(title2, 60, 27);

            // Adicionando a tabela
            let tabela = JSON.parse(JSON.stringify(this.dados));
            tabela = tabela.map(item => ({ id: item.id, data: moment(item.data).format('DD/MM/yyyy'), nome: item.nome, valor: item.valor }))
            tabela = tabela.map((item) => [item.id, item.data, item.nome, item.valor])
            doc.autoTable({
                head: headers,
                body: tabela,
                startY: 30

            });



            // Salvando o PDF
            doc.save("relatorio.pdf");
        }

    },
    mounted() {
        //this.getDizimista();
    }

}
</script>

<style scoped>
.munuBtn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.msgModal {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    border: solid 2px;
    background-color: darkgray;

    height: 140px;
    width: 380px;
    border-radius: 20px;
    font-size: large;
    /*   align-content: center;
    align-items: center; */
}

#btnModal {
    width: 140px;
}

.table {
    width: 100%;
}

.table tr th {
    padding: 8px;
}

.btn-novo {
    color: black;
    border: solid 1px #2196f3;
    padding: 10px;
    border-radius: 4px;
}

.btn-excluir {
    color: #2196f3;
    border: solid 1px #2196f3;
    padding: 10px;
    border-radius: 4px;
}

.btn-novo:hover {
    background-color: #2196f3;
    color: #fff;
}

.btn-excluir:hover {
    background-color: #ca2b25;
    color: #fff;
}
.elemento {
    padding-top: 5px;
    padding-right: 10px;
    padding-bottom: 7px;
    padding-left: 15px;
    margin: 10px;
}
</style>